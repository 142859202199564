const config = {
  apiKey: "AIzaSyAZOMVM8C8qB3BFjPqkZp7ZTs4DAZDKdLo",
  authDomain: "asteroid-321215.firebaseapp.com",
  projectId: "asteroid-321215",
  storageBucket: "asteroid-321215.appspot.com",
  messagingSenderId: "1010504788292",
  appId: "1:1010504788292:web:d4aff61e0d705e4646cf11",
  measurementId: "G-1ZBT21PF91"
}

export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error('No Firebase configuration object provided.' + '\n' +
    'Add your web app\'s configuration object to firebase-config.js')
  } else {
    return config
  }
}