import { useParams, useNavigate } from "react-router-dom"

function Terminal() {
  let { terminal_id } = useParams()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const {
    sn: serial
  } = Object.fromEntries(urlSearchParams.entries())
  let navigate = useNavigate()
  setTimeout(() => {
    navigate('/payment', { 
      state: {
        terminal_id,
        serial
      } 
    })
  }, 500)
  return (
    <div>Loading</div>
  )
}

export default Terminal