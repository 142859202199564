import React, { useState } from 'react'
import './payment.css'
// import venmo from '../images/Venmo3.png'
// import paypal from '../images/PayPal2.png'
import banner from '../images/banner.png'
import ReCAPTCHA from 'react-google-recaptcha'
const recaptchaRef = React.createRef()
import html2canvas from 'html2canvas'
import { initializeApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFirebaseConfig } from '../lib'
import SignaturePad from 'react-signature-pad-wrapper'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

function PaymentReceipt(props) {
  const {
    accountName,
    accountStreet,
    accountCity,
    accountState,
    accountZip,
    accountPhone,
    accountEmail,
    firstName,
    lastName,
    repairOrderId,
    street,
    zip,
    number, 
    type,
    amount,
    authCode
  } = props
  const [captureEmail, setCaptureEmail] = useState(false)
  const [email, setEmail] = useState()
  const handleClick = () => {
    console.log('click')
    if (!captureEmail) {
      setCaptureEmail(true)
    } else if (captureEmail && email) {
      html2canvas(document.getElementById('root')).then(function(canvas) {
        props.handleEmail({ canvas: canvas.toDataURL(), email })
      })
    }
  }
  return (
    <div>
      <h3>{accountName}</h3>
      <h5>{accountStreet}</h5>
      <h5>{accountCity}, {accountState} {accountZip}</h5>
      <h5>{accountPhone}</h5>
      <h5>{accountEmail}</h5>
      <div className="col d-flex justify-content-center">
        <div className="card bg-light mt-2 justify-content-center" style={{ width: "35rem" }}>
          <div className="card-header">
            Receipt of Payment
          </div>
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-sm">
                <b>Name</b>
                </div>
                <div className="col-sm">
                <b>Invoice</b> 
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  {firstName} {lastName}
                </div>
                <div className="col-sm">
                  {repairOrderId}
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <b>Street</b>
                </div>
                <div className="col-sm">
                  <b>Approval Code</b>
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                 {street}
                </div>
                <div className="col-sm">
                  {authCode}
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <b>Zip</b>
                </div>
                <div className="col-sm">
                  <b>Amount</b>
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  {zip}
                </div>
                <div className="col-sm">
                  {amount}
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <b>Card Type</b>
                </div>
                <div className="col-sm">
                  <b>Card #</b>
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  {type}
                </div>
                <div className="col-sm">
                  {number}
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                </div>
                <div className="col-sm">
                  <b>Status</b>
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                </div>
                <div className="col-sm">
                  PROCESSED
                </div>
              </div>
            </div>
            { captureEmail && (
              <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Email Address"
              onChange={event => setEmail(event.target.value)}
              data-html2canvas-ignore
              />
            )}
            <button
              type="submit"
              onClick={handleClick}
              className="btn btn-primary mt-1 mb-2"
              data-html2canvas-ignore
            >
              Email Receipt
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

// Initialize Firebase
const app = initializeApp(getFirebaseConfig())

class Payment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {
        ccnumber: {
          valid: false
        },
        ccexp: {
          valid: false
        },
        cvv: {
          valid: false
        }
      },
      firstName: '',
      lastName: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      amount: undefined,
      isSubmitting: false,
      submitDisabled: true,
      notInvalid: false,
      alertMessage: '',
      merchantId: undefined,
      repairOrderId: undefined,
      accountName: '',
      accountPhone: '',
      accountEmail: '',
      accountStreet: '',
      accountCity: '',
      accountState: '',
      accountZip: '',
      accountImage: '',
      receipt: false,
      hasApplePay: false,
      hasGooglePay: false,
      hasCFP: false,
    }
    this.setState = this.setState.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.finishSubmit = this.finishSubmit.bind(this)
    this.loadCollect = this.loadCollect.bind(this)
    this.handleEmail = this.handleEmail.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.toggleSignature = this.toggleSignature.bind(this)
    this.handleGetSignature = this.handleGetSignature.bind(this)
    this.fieldValidation = this.fieldValidation.bind(this)
    this.submitError = this.submitError.bind(this)
    this.updateSubmitDisabled = this.updateSubmitDisabled.bind(this)
    this.fieldsAvailable = this.fieldsAvailable.bind(this)
    this.signaturePadRef = React.createRef()
  }

  async componentDidMount() {
    const functions = getFunctions(app)
    // const getTokenizationKey = httpsCallable(functions, 'getTokenizationKey')
    const signedPayment = httpsCallable(functions, 'onCall-signedPayment')
    // this is a public key
    try {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const {
        id,
      } = Object.fromEntries(urlSearchParams.entries())
      
      const result = await signedPayment({ id })
      const {
        merchantId,
        repairOrderId,
        terminalId,
        amount,
        datetime, 
        expire, 
        refId,
        signature,
        accountName,
        accountPhone,
        accountEmail,
        accountStreet,
        accountCity,
        accountState,
        accountZip,
        accountImage,
        velox_token_key,
        firstName = '',
        lastName = '',
        phone = '',
        address = '',
        city = '',
        state = '',
        zip = '',
        cfpPartnerKey,
        hasApplePay = false,
        hasGooglePay = false,
        hasCFP = false,
      } = result.data
      this.setState({
        merchantId,
        repairOrderId,
        terminalId,
        amount,
        datetime, 
        expire, 
        refId,
        signature,
        accountName,
        accountPhone,
        accountEmail,
        accountStreet,
        accountCity,
        accountState,
        accountZip,
        accountImage,
        firstName,
        lastName,
        phone,
        address,
        city,
        state,
        zip,
        cfpPartnerKey,
        hasApplePay,
        hasGooglePay,
        hasCFP,
      })

      console.log('Key ', velox_token_key)
      const script = document.createElement("script")
      script.src = "https://velox.transactiongateway.com/token/Collect.js"
      script.async = false
      script.setAttribute('data-tokenization-key', velox_token_key)
      script.setAttribute('data-country', 'US')
      script.setAttribute('data-currency', 'USD')
      script.setAttribute('data-price', amount)
      if (script.readyState) {  // only required for IE <9
        script.onreadystatechange = () => {
          if ( script.readyState === "loaded" || script.readyState === "complete" ) {
            script.onreadystatechange = null
            this.loadCollect()
          }
        }
      } else {  //Others
        script.onload = () => {
          this.loadCollect()
        }
      }
      document.body.appendChild(script)
      if (hasCFP) {
        this.loadLendResults({ merchantId, cfpPartnerKey, phone, amount })
      }
     
    } catch (err) {
      console.log('error: ', err.message)
      this.setState({ isSubmitting: false, notInvalid: true })
    }
  }

  async loadLendResults({ merchantId, cfpPartnerKey, phone, amount }) {
    try {
      const functions = getFunctions(app)
      const loadLendResults = httpsCallable(functions, 'onCall-loadLendResults')
      const result = await loadLendResults({ merchantId, cfpPartnerKey, phone, amount })
      const { 
        lendResult,
        lendApplyUrl,
      } = result.data
      console.log('loadLendResults: ', lendResult, ' ', lendApplyUrl)
      this.setState({ lendResult, lendApplyUrl })
    } catch (err) {
      console.log('error: ', err.message)
      this.setState({ isSubmitting: false, notInvalid: true })
    }
  }

  loadCollect() {
    console.log('loadCollect')
    window.CollectJS.configure({
      variant: 'inline',
      styleSniffer: true,
      validationCallback: (field, valid, message) => {
        console.log('validationCallback: ', field, ' ', valid, ' ', message)
        this.fieldValidation({ field, valid, message })
      },
      fieldsAvailableCallback: () => {
        console.log('field loaded')
        this.fieldsAvailable()
      },
      timeoutCallback: () => {
        console.log('timeoutCallback: ')
        this.submitError(`The tokenization didn't respond in the expected timeframe.  This could be due to an invalid or incomplete field or poor connectivity`)
      },
      callback: (token) => {
        console.log(token)
        this.finishSubmit(token)
      },
      fields: {
        ccnumber: {
          placeholder: 'Card number',
          selector: '#ccnumber'
        },
        ccexp: {
          placeholder: 'Expiration (MM/YY)',
          selector: '#ccexp'
        },
        cvv: {
          placeholder: 'CVV',
          selector: '#cvv'
        },
        applePay: {
          style: {
            'button-style': 'white-outline',
            height: '40px',
          },
          requiredBillingContactFields: [
            'postalAddress',
            'name'
          ],
          type: 'check-out'
        },
        googlePay: {
          billingAddressRequired: true,
          billingAddressParameters: {
            format: 'FULL'
          },
          buttonType: 'checkout',
        }
      },
      "customCss": {
        "display": "block",
        "width": "100%",
        "padding": "0.375rem 0.75rem",
        "font-size": '1rem',
        "font-weight":'400',
        "height": "38px",
        "color": "#212529",
        "background-color": "#fff",
        "background-clip": "padding-box",
        "border": "1px solid #ced4da",
        "-webkit-appearance": "none",
        "appearance": "none",
        "border-radius": "0.375rem",
        "transition": "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
      }
    })
  }

  finishSubmit(response) {
    const {
      token,
      tokenType,
      card: { exp, number, type } = {},
      wallet: { billingInfo : { address1, address2, firstName, lastName, postalCode, city, state, country, phone } = {} } = {}
    } = response
    console.log('finishSubmit: ', JSON.stringify(response))
    console.log('finishSubmit-ext1: ', JSON.stringify({ token, exp, number, type }))
    console.log('finishSubmit-ext2: ', JSON.stringify({ address1, address2, firstName, lastName, postalCode, city, state, country, phone }))
    // this.setState({ isSubmitting: false, alertMessage: JSON.stringify({ address1, address2, firstName, lastName, postalCode, city, state, country, phone }), alertClass: 'alert alert-danger' })
    if (tokenType === 'googlePay' || tokenType === 'applePay') {
      this.setState( {
        firstName,
        lastName,
        address: `${address1}${address2 ? ' ' + address2 : ''}`,
        city,
        state,
        zip: postalCode,
        country,
        phone,
      })
    }
    this.completePayment({ token, exp, number, type, tokenType })
  }

  handleEmail({ canvas, email }) {
    const functions = getFunctions(app)
    const emailReceipt = httpsCallable(functions, 'onCall-emailReceipt')
    this.setState({ isSubmitting: true })
    const {
      merchantId,
      repairOrderId,
      terminalId,
      datetime,
      expire,
      refId,
      signature,
      amount,
    } = this.state
    console.log(merchantId, repairOrderId, terminalId, amount, datetime, expire, refId, signature)
    emailReceipt({ merchantId, repairOrderId, terminalId, datetime, expire, refId, signature, amount, emailTo: email, subject: 'Your Receipt', message: `Thank You`, file: canvas }).then(result => {
      const {
        status
      } = result.data
      console.log(status)
      if (status ===  'Success') {
        // this.setState({ isSubmitting: false, alertMessage: status, receipt: true, alertClass: 'alert alert-success' })
        window.location.reload(false)
      } else {
        this.setState({ isSubmitting: false, alertMessage: status, alertClass: 'alert alert-danger' })
      }
      // 
    }).catch(err => {
      console.log('error: ', err.message)
      this.setState({ isSubmitting: false, alertMessage: err.message, alertClass: 'alert alert-danger' })
    })
  }

  completePayment({ token, exp, number, type, tokenType }) {
    const functions = getFunctions(app)
    const makePayment = httpsCallable(functions, 'onCall-makePayment')

    const {
      merchantId,
      repairOrderId,
      terminalId,
      datetime,
      expire,
      refId,
      signature,
      amount,
      firstName,
      lastName,
      address,
      city,
      state,
      zip,
      recaptcha,
      capture_signature,
      phone,
    } = this.state
    
    makePayment({ recaptcha, capture_signature, tokenType, merchantId, repairOrderId, terminalId, datetime, expire, refId, signature, amount, firstName, lastName, address, city, state, zip, token, exp, number, type, phone }).then(result => {
      const {
        status,
        authCode
      } = result.data
      console.log('result.data: ', JSON.stringify(result.data))
      if (status ===  'Success') {
        this.setState({ isSubmitting: false, authCode, number, type, alertMessage: null, receipt: true, alertClass: null })
      } else {
        this.setState({ isSubmitting: false, alertMessage: status, alertClass: 'alert alert-danger' })
      }
      // window.location.reload(false)
    }).catch(err => {
      console.log('error: ', err.message)
      this.setState({ isSubmitting: false, alertMessage: err.message, alertClass: 'alert alert-danger' })
    })
  }

  fieldValidation({ field, valid, message }) {
    console.log(`${field} - ${valid} - ${message}`)
    const {
      fields
    } = this.state
    fields[field] = {
      valid,
      message,
    }
    let invalidKey = Object.keys(fields).find(key => !fields[key].valid)
    console.log('invalidKey: ', invalidKey)
    if (invalidKey) {
      this.setState({ isSubmitting: false, alertMessage: `${invalidKey} ${fields[invalidKey].message}`, alertClass: 'alert alert-danger', fields })
    } else {
      this.setState({ alertMessage: undefined, alertClass: undefined, fields })
    }
    // this.setState(fields)
    this.updateSubmitDisabled()
  }

  fieldsAvailable() {
    this.setState({ fieldsReady: true })
  }

  updateSubmitDisabled() {
    this.setState((state) => {
      // const hasSignature = !!state.capture_signature 
      // const hasRecaptcha = !!state.recaptcha
      const fieldsValid = Object.keys(state.fields).reduce((retBol, field) => {
        console.log('field: ' + JSON.stringify(state.fields[field]))
        return retBol && (state.fields[field].valid || false)
      }, true)
      console.log('fieldsValid: ', !fieldsValid)
      return {
        //submitDisabled: !fieldsValid || !hasRecaptcha || !hasSignature
        submitDisabled: !fieldsValid 
      }
    })
  }
  
  handleClear() {
    this.signaturePadRef && this.signaturePadRef.current && this.signaturePadRef.current.clear()
    this.updateSubmitDisabled()
  }
  
  handleGetSignature() {
    if (!this.signaturePadRef.current.isEmpty()) {
      const data = this.signaturePadRef.current.toDataURL()
      console.log('data: ', data)
      this.setState({ capture_signature: data })
      this.toggleSignature()
      this.updateSubmitDisabled()
    }
  }

  submitError(message) {
    this.setState({ isSubmitting: false, alertMessage: `${message}`, alertClass: 'alert alert-danger' })
  }
  
  onChange(value) {
    console.log("Captcha value:", value)
    this.setState({ recaptcha: value })
    this.updateSubmitDisabled()
  }

  handleSubmit(event) {
    event.preventDefault()
    console.log('handleSubmit: ' + this.state.showCaptchaSignature)
    const hasSignature = !!this.state.capture_signature 
    const hasRecaptcha = !!this.state.recaptcha 
    console.log('hasSignature: ' + hasSignature)
    console.log('hasRecaptcha: ' + hasRecaptcha)
    const {
      firstName,
      lastName,
      address,
      city,
      state,
      zip,
    } = this.state
    if (!firstName) {
      return this.submitError(`Billing First Name is required`)
    }
    if (!lastName) {
      return this.submitError(`Billing Last Name is required`)
    }
    if (!address) {
      return this.submitError(`Billing Street Address is required`)
    }
    if (!city) {
      return this.submitError(`Billing City is required`)
    }
    if (!state) {
      return this.submitError(`Billing State is required`)
    }
    if (!zip) {
      return this.submitError(`Billing Postal Code is required`)
    }
    if (!this.state.showCaptchaSignature) {
      this.setState({ showCaptchaSignature: true })
    } else if (!hasSignature) {
      return this.submitError(`Signature is required`)
    } else if (!hasRecaptcha) {
      return this.submitError(`Recaptcha is required`)
    } else if (hasSignature && hasRecaptcha) {
      this.setState({ isSubmitting: true })
      window.CollectJS.startPaymentRequest()
    }
  }

  toggleSignature() {
    this.setState((state) => { 
      return { signatureHidden: !state.signatureHidden }
    })
  }

  render() {
    if (this.state.receipt) { // eslint-disable-line no-constant-condition
      return (
        <div>
          { this.state.alertMessage && (
            <div className={this.state.alertClass} role="alert">
              {this.state.alertMessage}
            </div>
          )}
          { this.state.isSubmitting && (
            <div id="overlay">
              <div className="spinner"></div>
            </div>
          )}
          <PaymentReceipt {...this.state} handleEmail={this.handleEmail}/>
        </div>
      )
    }
    if (this.state.notInvalid) {
      return (
        <div className="App">
          <div className="container-fluid">
            <div className="row justify-content-center m-4">
            <div className="card">
              <div className="card-body">

              <h1>Payment for this link has already been processed.</h1>
              <h1>Thanks for using 360 Text-To-Pay</h1>
              </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    if (!this.state.merchantId) {
      return (
        <div className="App">
          { this.state.alertMessage ? (
          <div className={this.state.alertClass} role="alert">
            {this.state.alertMessage}
          </div>
        ) : (
          <div className="text-center">
            <div className="spinner-border m-5" style={{ width: 5 + 'rem', height: 5 + 'rem' }} role="status">
            </div>
          </div>
        )}
        </div>
      )
    }
    return (
      <div className="container-fluid">
        <div className="row justify-content-center">

        <div className="card bg-light mt-2" style={{ width: "35rem" }}>
        { this.state.isSubmitting && (
          <div id="overlay">
              <div className="spinner" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
          </div>
        )}
        {/* <div>
          <h2>Online Payment Processing</h2>
        </div> */}
        
        { this.state.accountImage && (
          <img src={this.state.accountImage} alt="image" className="mt-1 card-img-top"/>
        )}
        { this.state.alertMessage && (
          <div className={this.state.alertClass} role="alert">
            {this.state.alertMessage}
          </div>
        )}
       <h3>{this.state.accountName}</h3>
        <h5>{this.state.accountStreet}, {this.state.accountCity}, {this.state.accountState} {this.state.accountZip}</h5>
        
        <h5>{this.state.accountPhone}</h5>
        <h3>Total: ${this.state.amount}</h3>
        {this.state.invoiceUrl ? (<a href={this.state.invoiceUrl}>Invoice</a>) : ''}
        <form onSubmit={this.handleSubmit}>
          <div className="myborder  mb-1">
            <fieldset>
              <div className="row  mt-1 g-0">
                <div className="col">
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    placeholder="First name"
                    onChange={event => this.setState({ firstName: event.target.value })}
                    value={this.state.firstName}
                  />
                </div>
                <div className="col ms-1"> 
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    placeholder="Last name"
                    onChange={event => this.setState({ lastName: event.target.value })}
                    value={this.state.lastName}
                    size="30"
                  />
                </div>
              </div>
              <div className="mt-1"> 
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  placeholder="Phone"
                  onChange={event => this.setState({ phone: event.target.value })}
                  value={this.state.phone}
                  size="30"
                />
              </div>
              <div className="mt-1"> 
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  placeholder="Street address"
                  onChange={event => this.setState({ address: event.target.value })}
                  value={this.state.address}
                  size="60"
                />
              </div>
              <div className="row mt-1 g-0">
                <div className="col"> 
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    placeholder="City"
                    onChange={event => this.setState({ city: event.target.value })}
                    value={this.state.city}
                    size="30"
                  />
                </div>
                <div className="col ms-1"> 
                  <input
                    type="text"
                    name="state"
                    className="form-control"
                    placeholder="State"
                    onChange={event => this.setState({ state: event.target.value })}
                    value={this.state.state}
                    size="2"
                  />
                </div>
              </div>
              <div className="mt-1"> 
                <input
                  type="text"
                  name="zip"
                  className="form-control"
                  placeholder="ZIP code"
                  onChange={event => this.setState({ zip: event.target.value })}
                  value={this.state.zip}
                  size="10"
                />
              </div>
              <div className="mt-1">
                <div id="ccnumber"/>
              </div>
              <div className="mt-1">
                <div id="ccexp"/>
              </div>
              <div className="mt-1">
                <div id="cvv"/>
              </div>
            </fieldset>
              {this.state.showCaptchaSignature && this.state.signatureHidden && !this.state.capture_signature ? ( 
                <div className="mt-1"> 
                  <input
                    type="text"
                    name="signature"
                    className="form-control"
                    placeholder="Signature"
                    onClick={this.toggleSignature}
                  />
                </div>
                ) : this.state.showCaptchaSignature &&  this.state.signatureHidden && this.state.capture_signature ? (
                <div className="form-control mt-1" onClick={this.toggleSignature}>
                  <img src={this.state.capture_signature} alt="image" className="img-fluid"/>
                </div>
                ) : this.state.showCaptchaSignature ? (
                  <div>
                    <div className="mt-1 form-control">
                      <SignaturePad ref={this.signaturePadRef}/>
                    </div>
                    <div className="mt-1 mb-1">
                      <button type="button" onClick={this.handleClear} className="btn btn-light">Clear</button>
                      <button type="button" onClick={this.handleGetSignature} className="btn btn-primary ms-1">Save</button>
                    </div>
                  </div> 
                ) : <div/> }
                
                { this.state.showCaptchaSignature ? (
                <div className="mt-1 justify-content-center">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LeajoYgAAAAAAipHXxCDX8k3zOIo8tJ4IDd-MQ6"
                    onChange={this.onChange}
                  />  
                </div>
                ) : <div/>}
                <button
                  type="submit"
                  className="btnwide btn btn-primary mt-1"
                  disabled={this.state.isSubmitting || this.state.submitDisabled}
                >
                  Pay With Credit Card
                </button>
                { this.state.hasGooglePay && (
                  <div className="mb-1">
                    <div id="googlepaybutton"></div>
                  </div>
                )}
                { this.state.hasApplePay && window.ApplePaySession && (
                  <div className="mb-1">
                    <div id="applepaybutton"></div>
                  </div>
                )}
              </div>


              {/* <img style={{ width: 100 + '%', height: 65 + 'px' }} 
                src={venmo} alt="image" className="img-fluid rounded mt-1"/>
              <br/>
              
              <img style={{ width: 100 + '%', height: 65 + 'px' }} 
                src={paypal} alt="image" className="img-fluid rounded mt-1"/>
              <br/> */}

              { this.state.lendResult && (
                <div className="inline">
                  <img style={{ width: 100 + '%', height: 65 + 'px' }} 
                    src={banner} alt="image" className="img-fluid rounded mt-1"/>
                    <div className="centered">
                      <b>
                        <h7 >{this.state.lendResult}.&nbsp;&nbsp; 
                        { this.state.lendApplyUrl && (
                          <a target="_blank" href={this.state.lendApplyUrl}>Apply Now!</a>
                        )}
                        </h7>
                      </b>
                    </div>
                    <br/>
                    <br/>
                </div>  
              )}
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default Payment