import './App.css'
import { Routes, Route } from "react-router-dom"
import Payment from './components/payment.js'
import Invoice from './components/invoice.js'
import Terminal from './components/terminal.js'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="pay" element={<Payment />} ></Route>
        <Route path="invoice" element={<Invoice />} ></Route>
        <Route path="terminal/:terminal_id" element={<Terminal />} ></Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem", fontSize: "2em" }}>
              <p>403</p>
            </main>
          }
        />
      </Routes>
    </div>
  )
}

export default App
